import React from "react";

function Privacy() {
  return (
    <div>
      <div class="page-header wf-section">
        <h1 class="h1-title">Privacy Policy</h1>
        <p class="subheading-text" style={{ textAlign: "center" }}>
          We take privacy seriously. Here are the details.
        </p>
      </div>
      <div class="section-browse wf-section">
        <div class="browse-container w-container">
          <div class="w-richtext">
            <p>
              This privacy policy sets out how OSCEhub Limited uses and protects
              any information that you give when you use the website.
              <br />
              <br />
              Your privacy is of the utmost importance to us and we will never
              sell your e-mail address or any other personal details to third
              parties.
              <br />
              <br />
              When you make a purchase from the website we collect and store the
              following information:
            </p>
            <ul role="list">
              <li>your e-mail address</li>
              <li>your name</li>
              <li>your address</li>
              <li>your phone number if you supply one</li>
            </ul>
            <p>
              <br />
              Once you start using the website we also collect the following
              information:
            </p>
            <ul role="list">
              <li>OSCE stations attempted</li>
              <li>Progress with OSCE stations</li>
              <li>Marks on OSCE stations</li>
              <li>
                any messages or feedback you&#x27;ve sent to the     admin team
              </li>
              <li>
                details of when you login including the     time/date and IP
                address
              </li>
            </ul>
            <p>
              <br />
              All this information is stored on our secure servers. We
              don&#x27;t have access to,collect or store any credit/debit card
              details. The payment system is provided by Stripe and is separate
              from the website.
              <br />
              <br />
              We may send a subscriber an e-mail to welcome new subscribers,
              answer specific queries about the site (including reminding a user
              of their password), to answer specific queries regarding a
              question, your progress in passing the exam,updates about the
              website, promotions available and the development of the website
              etc.
              <br />
              <br />
              If OSCEhub Limited believes that your use of the site is unlawful
              or damaging to other users, we reserve the right to disclose the
              information obtained about you to the extent that it is reasonably
              necessary in our opinion to remedy the situation and prevent such
              conduct continuing.
              <br />
              <br />
              Your IP address may be stored and used to help identify you and to
              gather broad demographic information about you for statistical
              purposes. We may also use your IP address in helping to diagnose
              problems with our server. Your IPaddress may also be used to
              assist in the detection of fraud and we may pass this information
              to the police
              <br />
              <br />
              It is your right to have any information that we store about you
              erased. You can do this by e-mailing us at hello@oscehub.co.uk
              <br />
              <br />
              We use a Google Analytics &amp; HotJar services which places a
              cookie on the users computer and is used to provide marketing
              data.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
